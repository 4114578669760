<template>
  <b-modal
    id="user-email-modal"
    ref="my-modal"
    :title="`${$t('EMAIL_TO')}  ${user.firstname} ${user.lastname}`"
    ok-title="Send"
    cancel-variant="outline-secondary"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <b-form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-row>
        <!-- set date and time -->
        <b-col cols="12">
          <b-form-group>
            <b-form-input
              id="id-Subject"
              v-model="user.subject"
              maxlength="60"
              :placeholder="$t('SUBJECT')"
              :state="nameState"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <quill-editor
              id="id-description"
              v-model="user.message"
              maxlength="300"
              :options="editorOption"
              class="border-bottom-0"
              :state="nameState"
              required
            />
            <div
              id="quill-toolbar"
              class="d-flex justify-content-end border-top-0"
            >
              <!-- Add a bold button -->
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-underline" />
              <button class="ql-align" />
              <button class="ql-link" />
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup, BModal, VBModal, BRow, BCol, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
// eslint-disable-next-line import/no-extraneous-dependencies
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    BForm,
    BFormGroup,
    BModal,
    BRow,
    BCol,
    BFormInput,
    quillEditor,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'viewUser',
  },
  props: {
    viewUser: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      user: {
        email: '',
        subject: '',
        message: '',
      },
      nameState: null,
    }
  },
  watch: {
    viewUser: {
      handler() {
        if (this.viewUser) {
          this.user = this.viewUser
        }
      },
    },
  },
  setup() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your email',
    }

    const {
      successMessage, errorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      editorOption,
    }
  },
  methods: {
    ...mapActions('user', ['sendEmailUser']),
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    resetModal() {
      this.nameState = null
      this.set = {}
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      if (this.user.message) {
        this.sendEmailUser(this.user).then(() => {
          this.resetModal()
          this.$bvModal.hide('user-email-modal')
          this.successMessage(this.$i18n.t('MESSAGE.EMAIL_DISPATCHED'))
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
